import React from 'react';
import Slider from 'react-slick';
import { Button, IconNextArrow, IconPrevArrow } from '../../../components';
import {
  formatStrToQueryStr,
  isArrayLength,
  keepValidGermanChars,
  removeLinkAndSpan,
} from '../../../util/genericHelpers';
import { useIntl } from '../../../util/reactIntl';
import classNames from 'classnames';
import { getBlogData } from '../../../util/dataExtractors';
import { createResourceLocatorString } from '../../../util/routes';
import routeConfiguration from '../../../routing/routeConfiguration';
import { createSlug } from '../../../util/urlHelpers';

import css from './Sections.module.css';
import '../../../styles/slick/slick-theme.css';
import '../../../styles/slick/slick.css';
import { convertMarkdownToReact } from '../../../util/markdownHelpers';

function RenderNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <IconNextArrow className={css.icon} />
    </div>
  );
}

function RenderPrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <IconPrevArrow className={css.icon} />
    </div>
  );
}
const sliderSettings = {
  dots: false,
  arrows: true,
  nextArrow: <RenderNextArrow />,
  prevArrow: <RenderPrevArrow />,
  infinite: true,
  autoplay: false,
  centerMode: true,
  autoplaySpeed: 2000,
  speed: 500,
  slidesToShow: 5.2, // Display 4 items on desktop
  slidesToScroll: 1, // Scroll 1 item at a time
  variableWidth: false,
  focusOnSelect: false,
  responsive: [
    {
      breakpoint: 1600, // Adjust settings for smaller viewports
      settings: {
        slidesToShow: 4.5,
        variableWidth: false,
      },
    },
    {
      breakpoint: 1280, // Adjust settings for smaller viewports
      settings: {
        slidesToShow: 3.5,
        variableWidth: false,
      },
    },
    {
      breakpoint: 991, // Adjust settings for smaller viewports
      settings: {
        slidesToShow: 2.5,
        variableWidth: false,
      },
    },
    {
      breakpoint: 580, // Adjust settings for smaller viewports
      settings: {
        slidesToShow: 2.5,
        variableWidth: false,
      },
    },
    {
      breakpoint: 480, // Adjust settings for smaller viewports
      settings: {
        slidesToShow: 1.5,
        variableWidth: false,
      },
    },
  ],
};

function SeoSectionLanding(props) {
  const {
    showGeneralAmenities,
    iswhiteBgSection,
    blogs,
    history,
    renderRedirectIcons,
    searchKey,
  } = props;

  const intl = useIntl();
  if (!isArrayLength(blogs)) {
    return <></>;
  }

  const popularSearchLabel = intl.formatMessage({
    id: 'SeoSectionLanding.popularSearchLabel',
  });

  const submitButton = intl.formatMessage({
    id: 'SeoSectionLanding.submitButton',
  });

  return (
    <div
      className={classNames(css.fullSlider, {
        [css.whiteBgSection]: iswhiteBgSection,
      })}
    >
      <h3
        className={classNames(css.recentCampingTitle, {
          [css.popularSearchLabelWhiteBg]: iswhiteBgSection,
        })}
      >
        {intl.formatMessage({
          id: 'SeoSectionLanding.recentCampingTitle',
        })}
      </h3>
      <Slider {...sliderSettings}>
        {isArrayLength(blogs) &&
          blogs.map((b, i) => {
            const { title, description = '', image } = getBlogData(b) || {};
            return (
              <div key={i} className={css.sliderItem}>
                <img src={image} alt={title} />
                <div className={css.sliderItemContent}>
                  <p>
                    <span className={css.markdownTitle}>{removeLinkAndSpan(title)}</span>{' '}
                    {description ? `${keepValidGermanChars(description.replace(/#/g, ' '))}` : ''}
                  </p>
                  <Button
                    onClick={() => {
                      history.push(
                        createResourceLocatorString(
                          'SingleBlogPage',
                          routeConfiguration(),
                          {
                            id: b?.id,
                            slug: createSlug(title),
                          },
                          {}
                        )
                      );
                    }}
                    type="button"
                  >
                    {submitButton}
                  </Button>
                </div>
              </div>
            );
          })}
      </Slider>
      <h3
        className={classNames(css.popularSearchLabel, {
          [css.popularSearchLabelWhiteBg]: iswhiteBgSection,
        })}
      >
        {popularSearchLabel}
      </h3>
      {showGeneralAmenities || true ? (
        <div className={css.showGeneralAmenities}>
          {isArrayLength(renderRedirectIcons) &&
            renderRedirectIcons.map((rri, i) => (
              <div
                key={i}
                className={css.amenityBlock}
                onClick={() => {
                  history.push(
                    createResourceLocatorString(
                      'SearchPage',
                      routeConfiguration(),
                      {},
                      { [searchKey]: formatStrToQueryStr([rri?.key]) }
                    )
                  );
                }}
              >
                <img src={rri?.icon} />
                <p>{rri?.label}</p>
              </div>
            ))}
        </div>
      ) : null}
    </div>
  );
}

export default SeoSectionLanding;
