import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import SectionHero from './SectionHero/SectionHero';
import SeenOnWrapper from './Sections/SeenOnWrapper';
import SeoSectionLanding from './Sections/SeoSectionLanding';
import HowItWorks from './Sections/HowItWorks';
import SeoImageSectionLanding from './Sections/SeoImageSectionLanding';
import ExploreSection from './Sections/ExploreSection';
import SectionCampsites from './Sections/SectionCampsites';
import BecomeHostSection from './Sections/BecomeHostSection';
import NewsletterSection from './CommonSections/NewsletterSection';
import CampingInSection from './CommonSections/CampingInSection';
import SectionCar from './CommonSections/SectionCar';
import { getDefaultLocale, isArrayLength } from '../../util/genericHelpers';
import {
  essentialsOptions,
  extrasOptions,
  generalOptions,
  parkingLocationOptions,
  vehicleTypesOptions,
} from '../../util/fieldsOptions';
import blogsJSON from '../../cache/blogsData.json';
import css from './LandingPage.module.css';

const defaultLocale = getDefaultLocale();
const blogsData = blogsJSON[defaultLocale];

export const blogsSectionOrderById = {
  de: [
    13,
    4,
    478,
    551,
    71,
    15,
    113,
    7,
    21,
    31,
    591,
    570,
    580,
    577,
    584,
    589,
    14,
    590,
    565,
    558,
    114,
    72,
    6,
    103,
    99,
    98,
    20,
    13,
    4,
    478,
  ],
  it: [
    177,
    152,
    532,
    556,
    314,
    351,
    442,
    472,
    153,
    418,
    603,
    575,
    618,
    604,
    610,
    382,
    596,
    569,
    563,
    176,
    188,
    544,
    263,
    495,
    273,
    343,
    177,
    151,
    532,
  ],
  pt: [
    181,
    151,
    527,
    557,
    313,
    355,
    446,
    475,
    157,
    419,
    602,
    574,
    614,
    583,
    588,
    611,
    386,
    595,
    612,
    562,
    175,
    187,
    543,
    262,
    494,
    277,
    342,
    181,
    152,
    527,
  ],
  es: [
    180,
    150,
    531,
    555,
    312,
    354,
    445,
    476,
    156,
    420,
    601,
    573,
    617,
    582,
    587,
    609,
    385,
    594,
    568,
    561,
    174,
    186,
    541,
    259,
    493,
    276,
    339,
    180,
    150,
    531,
  ],
  en: [
    179,
    149,
    530,
    554,
    311,
    353,
    444,
    474,
    155,
    421,
    600,
    572,
    616,
    581,
    586,
    607,
    384,
    592,
    567,
    560,
    173,
    185,
    542,
    261,
    491,
    275,
    341,
    179,
    149,
    530,
  ],
  fr: [
    178,
    148,
    529,
    553,
    310,
    352,
    443,
    473,
    154,
    422,
    599,
    571,
    615,
    579,
    585,
    608,
    383,
    593,
    566,
    559,
    172,
    184,
    540,
    260,
    492,
    274,
    340,
    178,
    148,
    529,
  ],
  nl: [
    182,
    147,
    528,
    552,
    309,
    356,
    447,
    477,
    158,
    423,
    598,
    576,
    619,
    578,
    605,
    606,
    387,
    597,
    613,
    564,
    171,
    183,
    539,
    264,
    496,
    278,
    344,
    182,
    147,
    528,
  ],
};

export const blogIds = blogsSectionOrderById[defaultLocale];
function RenderDesktopLandingPage(props) {
  const { viewport, history, listings, homepageSections } = props;

  const [allBlogs, setAllBlogs] = useState([]);

  useEffect(() => {
    let isMounted = true;

    if (isArrayLength(blogsData)) {
      const blogs = blogsData.filter(b => blogsSectionOrderById[defaultLocale].includes(b?.id));
      setAllBlogs(blogs);
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className={css.pageContent}>
      <SectionHero viewport={viewport} history={history} />
      <div className={css.halfSliderSection}>
        <SeenOnWrapper
          translations={{ title: 'SeenOnWrapper.title', description: 'SeenOnWrapper.description' }}
          renderIcon="https://dih6yo2fd8n78.cloudfront.net/icon1.png"
          listings={isArrayLength(listings) ? listings.slice(0, 20) : []}
        />
      </div>
      <div className={css.fullSliderSection}>
        <SeoSectionLanding
          history={history}
          blogs={isArrayLength(allBlogs) && allBlogs.slice(0, 10)}
          renderRedirectIcons={essentialsOptions.filter(e =>
            [
              'toilet',
              'electricity',
              'shower',
              'water-hookup',
              'daily-waste-disposal',
              'fire-pit',
            ].includes(e.key)
          )}
          searchKey="pub_essentials"
          iswhiteBgSection={true}
        />
      </div>
      <div className={css.howItWorkSection}>
        <HowItWorks />
      </div>
      <div className={css.imageGallarySection}>
        <SeoImageSectionLanding sectionIndex={1} homepageSections={homepageSections} />
      </div>

      <div className={css.becomeHostSection}>
        <BecomeHostSection />
      </div>
      <div className={classNames(css.fullSliderSection, css.greenBgSection)}>
        <SeoSectionLanding
          blogs={isArrayLength(allBlogs) && allBlogs.slice(10, 20)}
          renderRedirectIcons={extrasOptions.filter(e =>
            [
              'garden',
              'seating-options',
              'sink',
              'washing-machine',
              'playground',
              'farm-tours',
            ].includes(e.key)
          )}
          searchKey="pub_extras"
          history={history}
        />
      </div>
      <div className={css.newsletterSection}>
        <NewsletterSection onSendNewsLetter={() => {}} />
      </div>
      <div className={css.imageGallarySection}>
        <SeoImageSectionLanding sectionIndex={2} isInverted homepageSections={homepageSections} />
      </div>
      <div className={classNames(css.compositeSection, css.exploreBlog)}>
        <SectionCar />
      </div>
   
      <div className={css.imageGallarySection}>
        <SeoImageSectionLanding sectionIndex={3} homepageSections={homepageSections} />
      </div>
      <div className={classNames(css.fullSliderSection, css.greenBgSection)}>
        <SeoSectionLanding
          renderRedirectIcons={vehicleTypesOptions}
          blogs={isArrayLength(allBlogs) && allBlogs.slice(20)}
          history={history}
          searchKey="pub_vehicleTypes"
          showGeneralAmenities
        />
      </div>
      <div className={css.imageGallarySection}>
        <SeoImageSectionLanding sectionIndex={4} isInverted homepageSections={homepageSections} />
      </div>
      <div className={css.campingInSection}>
        <CampingInSection />
      </div>
    </div>
  );
}

export default RenderDesktopLandingPage;