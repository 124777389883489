import React from 'react';
import { FieldLocationAutocompleteInput } from '../../../../components';
import css from './LandingSearchForm.module.css';

function RenderLocationAutoComplete(props) {
  const { values, intl, onOpenFilterModal, isSectionHero } = props;
  return (
    <FieldLocationAutocompleteInput
      rootClassName={css.locationAddress}
      inputClassName={css.locationAutocompleteInput}
      iconClassName={css.locationAutocompleteInputIcon}
      predictionsClassName={css.predictionsRoot}
      validClassName={css.validLocation}
      isSectionHero={isSectionHero}
      name="location"
      placeholder={intl.formatMessage({
        id: 'LandingSearchForm.searchSpots',
      })}
      useDefaultPredictions={true}
      onOpenFilterModal={onOpenFilterModal}
      hideSearchIcon
      format={v => v}
      valueFromForm={values?.location}
    />
  );
}

export default RenderLocationAutoComplete;
