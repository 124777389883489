import React from 'react';
import { useIntl } from '../../../util/reactIntl';
import { isArrayLength } from '../../../util/genericHelpers';
import { Button, IconNextArrow, IconPrevArrow, LandingListingCard } from '../../../components';
import Slider from 'react-slick';
import { GENERAL_SEO } from '../../../util/enums';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { createResourceLocatorString } from '../../../util/routes';
import routeConfiguration from '../../../routing/routeConfiguration';
import { partnerImages, partnerLogos } from '../../../util/dataExtractors';
import classNames from 'classnames';

import css from './Sections.module.css';
import '../../../styles/slick/slick-theme.css';
import '../../../styles/slick/slick.css';

function RenderNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <IconNextArrow className={css.icon} />
    </div>
  );
}

function RenderPrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <IconPrevArrow className={css.icon} />
    </div>
  );
}
const sliderSettings = {
  dots: false,
  arrows: true,
  nextArrow: <RenderNextArrow />,
  prevArrow: <RenderPrevArrow />,
  infinite: true,
  autoplay: false,
  autoplaySpeed: 2000,
  speed: 500,
  slidesToShow: 3, // Display 4 items on desktop
  slidesToScroll: 1, // Scroll 1 item at a time
  variableWidth: false,
  focusOnSelect: false,
  centerMode: true,
  responsive: [
    {
      breakpoint: 1500, // Adjust settings for smaller viewports
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 1300, // Adjust settings for smaller viewports
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 992, // Adjust settings for smaller viewports
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 580, // Adjust settings for smaller viewports
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 480, // Adjust settings for smaller viewports
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

function SeenOnWrapper(props) {
  const { listings, renderIcon, translations, index } = props;
  const intl = useIntl();
  const history = useHistory();

  const renderPartnerIcons = index === 2 ? partnerLogos : partnerImages;
  return (
    <div className={css.moreAbout}>
      <div className={css.sectionListings}>
        <div className={css.sectionFilter}>
          <img src={renderIcon} />
          <h2>{intl.formatMessage({ id: translations?.title })}</h2>
          <p>{intl.formatMessage({ id: translations?.description })}</p>
          <Button
            onClick={() => {
              history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, {}));
            }}
            type="button"
          >
            {intl.formatMessage({ id: 'SeenOnWrapper.exploreNow' })}
          </Button>
        </div>
        <div className={css.halfSlider}>
          <Slider {...sliderSettings}>
            {isArrayLength(listings)
              ? listings.map(l => (
                  <LandingListingCard
                    className={css.listingCard}
                    key={l?.id?.uuid}
                    listing={l}
                    setActiveListing={() => {}}
                  />
                ))
              : null}
          </Slider>
        </div>
      </div>
      <div className={css.ourPartnerSection}>
        <h2>
          {' '}
          {intl.formatMessage({
            id: index === 2 ? 'SeenOnWrapper.ourPartners' : 'SeenOnWrapper.seenOn',
          })}
        </h2>
        <div className={classNames(css.seenOn, css.partnerImages)}>
          {renderPartnerIcons.map((img, i) => {
            return (
              <div className={css.partnerImg} key={i}>
                <img src={img} key={i} alt={GENERAL_SEO} className={css.partnerImage} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default SeenOnWrapper;
