import React from 'react';
import { Button, Icons } from '../../../components';
import { useIntl } from '../../../util/reactIntl';
import css from './Sections.module.css';
import { EXTERNAL_LINKS } from '../../../util/enums';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { createResourceLocatorString } from '../../../util/routes';
import routeConfiguration from '../../../routing/routeConfiguration';

function BecomeHostSection() {
  const intl = useIntl();
  const history = useHistory();

  return (
    <div className={css.becomeHostContent}>
      <h2>{intl.formatMessage({ id: 'BecomeHostSection.title' })}</h2>
      <div className={css.featureSection}>
        <div className={css.featureBlock}>
          <Icons name="userIcon" />
          <h2>{intl.formatMessage({ id: 'BecomeHostSection.signUp' })}</h2>
          <p>{intl.formatMessage({ id: 'BecomeHostSection.signUpDescription' })}</p>
        </div>
        <div className={css.featureBlock}>
          <Icons name="calendarIcon" />
          <h2>{intl.formatMessage({ id: 'BecomeHostSection.hostGuests' })}</h2>
          <p>{intl.formatMessage({ id: 'BecomeHostSection.hostGuestsDescription' })}</p>
        </div>
        <div className={css.featureBlock}>
          <Icons name="moneyHandIcon" />
          <h2>{intl.formatMessage({ id: 'BecomeHostSection.earnMoney' })}</h2>
          <p>{intl.formatMessage({ id: 'BecomeHostSection.earnMoneyDescription' })}</p>
        </div>
      </div>
      <div className={css.bottomButtonsWrapper}>
        <Button
          className={css.whiteBorderBtn}
          type="button"
          onClick={() => {
            if (typeof window !== 'undefined') {
              window.open(EXTERNAL_LINKS.calendly, '_blank');
            }
          }}
        >
          {intl.formatMessage({ id: 'BecomeHostSection.scheduleCall' })}
        </Button>
        <Button
          type="button"
          onClick={() => {
            history.push(
              createResourceLocatorString('NewListingPage', routeConfiguration(), {}, {})
            );
          }}
        >
          {intl.formatMessage({ id: 'BecomeHostSection.createListing' })}
        </Button>
      </div>
    </div>
  );
}

export default BecomeHostSection;
