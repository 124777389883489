import React from 'react';
import { useIntl } from '../../../util/reactIntl';
import LandingSearchForm from './LandingSearchForm/LandingSearchForm';
import { Heading, NamedLink } from '../../../components';
import yaavLogo from '../../../assets/youandaview.png';
import { GENERAL_SEO } from '../../../util/enums';
import { searchTitle } from '../../../routing/routeConfiguration';

import css from './SectionHero.module.css';
function SectionHero(props) {
  const { history, viewport } = props;

  const intl = useIntl();

  const handleSubmit = values => {
    const urlString = `Europe&bounds=62.33995248%2C19.17157943%2C25.98756225%2C-11.07927511&pub_isInfoOnly=false`;
    history.push(`/${searchTitle}?${urlString}`);
  };

  const title = intl.formatMessage({ id: 'SectionHero.title' });
  const description = intl.formatMessage({ id: 'SectionHero.description' });
  const searchAll = intl.formatMessage({ id: 'SectionHero.searchAll' });

  return (
    <div className={css.heroContainer}>
      <div className={css.contentWidth}>
        <div className={css.heroContent}>
          <img src={yaavLogo} alt={GENERAL_SEO} />
          <Heading as="h1" rootClassName={css.heading}>
            {title}
          </Heading>
          <p> {description}</p>
          <div className={css.heroSearchSec}>
            {/* <div className={css.heroSearchWrapper}>
              <LandingSearchForm
                rootClassName={css.landingSearchForm}
                onSubmit={handleSubmit}
                intl={intl}
                viewport={viewport}
              />
            </div> */}
            <NamedLink
              className={css.searchAllBtn}
              name="SearchPage"
              to={{
                search:
                  '?Europe&bounds=62.33995248%2C19.17157943%2C25.98756225%2C-11.07927511&pub_isInfoOnly=false',
              }}
            >
              {searchAll}
            </NamedLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionHero;
