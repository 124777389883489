import React, { useState, useEffect } from 'react';
import { func, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { intlShape, injectIntl } from '../../../../util/reactIntl';
import { Button, Form, IconSpinner, Icons, Spinner } from '../../../../components';
import IconSearchDesktop from '../../../../components/Topbar/TopbarSearchForm/IconSearchDesktop';
import IconCalendar from '../../../../components/IconCalendar/IconCalendar';
import IconVehicle from '../../../../components/IconVehicle/IconVehicle';
import { useMyContextFunctions } from '../../../../context/ContextFunctions';
import { TopbarFilterTypes } from '../../../../util/enums';
import { EventBus } from '../../../../util/eventHelpers';
import RenderLocationAutoComplete from './RenderLocationAutoComplete';

import css from './LandingSearchForm.module.css';

const LandingSearchFormComponent = props => {
  const [showRightSearch, setShowRightSearch] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowRightSearch(true);
    }, 2000); // wait 2 seconds before showing

    return () => clearTimeout(timer);
  }, []);

  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const { rootClassName, className, values, intl, handleSubmit } = formRenderProps;
        const classes = classNames(rootClassName || css.root, className);
        const { onSetGlobalFilterModal, onToggleTopbarFilter } = useMyContextFunctions();

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <RenderLocationAutoComplete
              intl={intl}
              values={values}
              isSectionHero
              onOpenFilterModal={() => {
                onSetGlobalFilterModal(true, TopbarFilterTypes.LOCATION);
                onToggleTopbarFilter(true);
                EventBus.dispatch('focusInput', { showCursor: true });
              }}
            />

            {/* Show spinner while waiting */}
            <div className={css.rightSearchWrapper}>
              {showRightSearch && (
                <>
                  {' '}
                  <div className={css.calendarSection}>
                    <button
                      className={classNames(css.calendarBtn, {
                        [css.selected]: values?.dates,
                      })}
                      type="button"
                      onClick={() => onSetGlobalFilterModal(true, TopbarFilterTypes.DATES)}
                    >
                      <IconCalendar />
                    </button>
                  </div>
                  <div className={css.vehicleSection}>
                    <button
                      className={classNames(css.filterBtn, {
                        [css.selected]: values?.pub_vehicleTypes,
                      })}
                      type="button"
                      onClick={() => onSetGlobalFilterModal(true, TopbarFilterTypes.FILTERS)}
                    >
                      <Icons name="camping" />
                    </button>
                  </div>
                  <div className={css.vehicleSection}>
                    <button
                      className={classNames(css.filterBtn, {
                        [css.selected]: values?.pub_vehicleTypes,
                      })}
                      type="button"
                      onClick={() => onSetGlobalFilterModal(true, TopbarFilterTypes.EXTRA_FILTERS)}
                    >
                      <Icons name="filter" />
                    </button>
                  </div>
                  <Button
                    className={css.searchBtn}
                    onClick={() => props.onSubmit(values)}
                    type="submit"
                  >
                    <IconSearchDesktop />
                  </Button>
                </>
              )}
              {!showRightSearch && <div className={css.iconSpinner} />}{' '}
            </div>
          </Form>
        );
      }}
    />
  );
};

LandingSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

LandingSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  onSubmit: func.isRequired,
  intl: intlShape.isRequired,
};

const LandingSearchForm = injectIntl(LandingSearchFormComponent);

export default LandingSearchForm;
