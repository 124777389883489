import React from 'react';
import Slider from 'react-slick';
import { useIntl } from '../../../util/reactIntl';
import { IconNextArrow, IconPrevArrow } from '../../../components';

import '../../../styles/slick/slick-theme.css';
import '../../../styles/slick/slick.css';
import css from './CommonSections.module.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import classNames from 'classnames';

function RenderNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <IconNextArrow className={css.icon} />
    </div>
  );
}

function RenderPrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <IconPrevArrow className={css.icon} />
    </div>
  );
}

function CampingInSection(props) {
  const { isMobileSection } = props;
  const intl = useIntl();
  const history = useHistory();
  const campingLocations = [
    {
      title: intl.formatMessage({ id: 'CampingInSection.Gardasee' }),
      link: 'camping/Lake%20Garda',
      image:
        'https://yaav-blog.s3.eu-central-1.amazonaws.com/Italienischer_Hafen_Gardasee_ff08066057.jpg',
    },
    {
      title: intl.formatMessage({ id: 'CampingInSection.Ostsee' }),
      link: 'camping/Baltic%20Sea',
      image: 'https://yaav-blog.s3.eu-central-1.amazonaws.com/Ostsee_ffa1178eeb.jpg',
    },
    {
      title: intl.formatMessage({ id: 'CampingInSection.Kroatien' }),
      link: 'camping/Kroatien',
      image: 'https://yaav-blog.s3.eu-central-1.amazonaws.com/Kroatien_d27f43563d.jpg',
    },
    {
      title: intl.formatMessage({ id: 'CampingInSection.Italien' }),
      link: 'camping/Italy',
      image: 'https://yaav-blog.s3.eu-central-1.amazonaws.com/Italien_f667d35cd0.jpg',
    },
    {
      title: intl.formatMessage({ id: 'CampingInSection.Frankreich' }),
      link: 'camping/France',
      image: 'https://yaav-blog.s3.eu-central-1.amazonaws.com/Frankreich_a5cd8a3fa1.jpg',
    },
    {
      title: intl.formatMessage({ id: 'CampingInSection.Bodensee' }),
      link: 'camping/Lake%20Constance',
      image: 'https://yaav-blog.s3.eu-central-1.amazonaws.com/Bodensee_0516ac096a.jpg',
    },
    {
      title: intl.formatMessage({ id: 'CampingInSection.Nordsee' }),
      link: 'camping/North%20Sea',
      image: 'https://yaav-blog.s3.eu-central-1.amazonaws.com/Nordsee_91ebdb3a41.jpg',
    },
    {
      title: intl.formatMessage({ id: 'CampingInSection.Holland' }),
      link: 'camping/Netherlands',
      image: 'https://yaav-blog.s3.eu-central-1.amazonaws.com/Niederlande_f7b1e2e47f.jpg',
    },
    {
      title: intl.formatMessage({ id: 'CampingInSection.Lago' }),
      link: 'camping/Lake%20Maggiore',
      image: 'https://yaav-blog.s3.eu-central-1.amazonaws.com/Lago_Maggiore_1_8e54a2d9a4.jpg',
    },
    {
      title: intl.formatMessage({ id: 'CampingInSection.Hopfensee' }),
      link: 'camping/Hopfensee',
      image: 'https://yaav-blog.s3.eu-central-1.amazonaws.com/Hopfensee_2_2524e549fc.jpg',
    },
    {
      title: intl.formatMessage({ id: 'CampingInSection.Tirol' }),
      link: 'camping/Tyrol',
      image: 'https://yaav-blog.s3.eu-central-1.amazonaws.com/Tirol_74ba2704da.jpg',
    },
    {
      title: intl.formatMessage({ id: 'CampingInSection.Chiemsee' }),
      link: 'camping/Chiemsee',
      image: 'https://yaav-blog.s3.eu-central-1.amazonaws.com/chiemsee_ee0c5d5c84.jpg',
    },
    {
      title: intl.formatMessage({ id: 'CampingInSection.Allgäu' }),
      link: 'camping/Allg%C3%A4u',
      image:
        'https://yaav-blog.s3.eu-central-1.amazonaws.com/malte_schmidt_co_U_Znech6qw_unsplash_02a75a45a4.jpg',
    },
    {
      title: intl.formatMessage({ id: 'CampingInSection.Österreich' }),
      link: 'camping/Austria',
      image: 'https://yaav-blog.s3.eu-central-1.amazonaws.com/Oesterreich_030f5ed3d6.jpg',
    },
  ];

  const sliderSettings = {
    dots: false,
    arrows: isMobileSection ? false : true,
    nextArrow: <RenderNextArrow />,
    prevArrow: <RenderPrevArrow />,
    infinite: true,
    speed: 500,
    slidesToShow: 5.4, // Display 4 items on desktop
    slidesToScroll: 1, // Scroll 1 item at a time
    variableWidth: false,
    focusOnSelect: false,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1440, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 3.6,
          variableWidth: false,
        },
      },
      {
        breakpoint: 1280, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 2.8,
          variableWidth: false,
        },
      },
      {
        breakpoint: 768, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 2.8,
          variableWidth: false,
        },
      },
      {
        breakpoint: 580, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 2.5,
          variableWidth: false,
        },
      },
      {
        breakpoint: 480, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 1.5,
          variableWidth: false,
        },
      },
    ],
  };

  return (
    <div className={css.campingInContent}>
      <h3 className={classNames(css.popularSearchLabel)}>
        {intl.formatMessage({ id: 'CampingInSection.titleLabel' })}
      </h3>
      <Slider {...sliderSettings}>
        {campingLocations.map((c, i) => (
          <div className={css.sliderItem} key={i} onClick={() => history.push(c.link)}>
            <img src={c.image} alt={c.title}></img>
            <h2>{c.title}</h2>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default CampingInSection;
