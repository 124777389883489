import React from 'react';
import { isArrayLength } from '../../../util/genericHelpers';
import { Button } from '../../../components';
import classNames from 'classnames';
import { renderIcons } from '../Sections/SeoImageSectionLanding';
import css from './MobileSections.module.css';

function SeoPageImageMobile(props) {
  const { isInverted, sectionIndex, homepageSections } = props;

  const section = isArrayLength(homepageSections) && homepageSections[sectionIndex - 1];

  if (!section) {
    <></>;
  }
  const seoLinks = section?.seo;

  return (
    <div className={classNames(css.imageGallary, isInverted ? css.imageGallary2 : null)}>
      <div className={css.gallarySec}>
        {isArrayLength(seoLinks) &&
          seoLinks?.slice(0, 4)?.map((s, i) => (
            <div
              key={i}
              role="button"
              className={css.imgBlock}
              onClick={() => {
                if (typeof window !== 'undefined') {
                  window.location.href = s?.link;
                }
              }}
            >
              <img src={s?.image?.data?.attributes?.url} alt={s?.title} />
              <h2>{s?.title}</h2>
            </div>
          ))}
      </div>
      <div className={css.sectionFilter}>
        {renderIcons[sectionIndex]}
        <h2>{section?.title}</h2>
        <p>{section?.description} </p>
        <Button
          type="button"
          onClick={() => {
            if (typeof window !== 'undefined') {
              window.location.href = section?.link;
            }
          }}
        >
          {section?.buttonText}
        </Button>
      </div>
    </div>
  );
}

export default SeoPageImageMobile;
