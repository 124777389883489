import React, { memo, useEffect, useRef, useState } from 'react';
import css from './LandingPage.module.css';
import MobileSectionHero from './SectionHero/MobileSectionHero';
import FilterBySection from './MobileSections/FilterBySection';
import SeoPageMobile from './MobileSections/SeoPageMobile';
import SingleCarSlider from './MobileSections/SingleCarSlider';
import SeoPageImageMobile from './MobileSections/SeoPageImageMobile';
import SectionCar from './CommonSections/SectionCar';
import SectionHostMobile from './MobileSections/SectionHostMobile';
import NewsletterSection from './CommonSections/NewsletterSection';
import CampingInSection from './CommonSections/CampingInSection';
import classNames from 'classnames';
import { essentialsOptions, parkingLocationOptions } from '../../util/fieldsOptions';
import { getDefaultLocale, isArrayLength } from '../../util/genericHelpers';
import blogsJSON from '../../cache/blogsData.json';
import { blogsSectionOrderById } from './RenderDesktopLandingPage';
import { isEqual } from 'lodash';

const defaultLocale = getDefaultLocale();
const blogsData = blogsJSON[defaultLocale];

const RenderMobileLandingPage = memo(
  props => {
    const { viewport, history, listings, intl, isMobileSection, homepageSections } = props;

    const [allBlogs, setAllBlogs] = useState([]);

    useEffect(() => {
      let isMounted = true;

      if (isArrayLength(blogsData)) {
        const blogs = blogsData.filter(b => blogsSectionOrderById[defaultLocale].includes(b?.id));
        setAllBlogs(blogs);
      }
      return () => {
        isMounted = false;
      };
    }, []);

    return (
      <div className={css.pageContent}>
        <MobileSectionHero
          viewport={viewport}
          history={history}
          isMobileSection={isMobileSection}
        />
        <div className={css.halfSliderSection}>
          <FilterBySection
            translations={{
              title: 'SeenOnWrapper.title',
              description: 'SeenOnWrapper.description',
            }}
            renderIcon="https://dih6yo2fd8n78.cloudfront.net/icon1.png"
            listings={isArrayLength(listings) ? listings.slice(0, 20) : []}
            isMobileSection={isMobileSection}
          />
        </div>
        <div
          className={classNames(css.fullSliderSection, css.greenBgSection, {
            [css.seoMobileSection]: isMobileSection,
          })}
        >
          <SeoPageMobile
            history={history}
            blogs={isArrayLength(allBlogs) && allBlogs.slice(0, 10)}
            intl={intl}
            renderRedirectIcons={essentialsOptions.filter(e =>
              [
                'toilet',
                'electricity',
                'shower',
                'water-hookup',
                'daily-waste-disposal',
                'fire-pit',
              ].includes(e.key)
            )}
            searchKey="pub_essentials"
            isMobileSection={isMobileSection}
            isSeoSlider={true}
          />
        </div>
        <div className={css.parkingSection}>
          <SingleCarSlider intl={intl} isMobileSection={isMobileSection} />
        </div>
        <div
          className={classNames(css.imageGallarySection, {
            [css.imageGallarySectionMobile]: isMobileSection,
          })}
        >
          <SeoPageImageMobile
            intl={intl}
            sectionIndex={1}
            isMobileSection={isMobileSection}
            homepageSections={homepageSections}
          />
        </div>

        <div
          className={classNames(css.compositeSection, {
            [css.compositeSectionMobile]: isMobileSection,
            [css.isCompositeSection]: true,
          })}
        >
          <SectionCar isCompositeSection isMobileSection={isMobileSection} />
        </div>
        <div className={css.halfSliderSection}>
          <FilterBySection
            translations={{
              title: 'SeenOnWrapper.title',
              description: 'SeenOnWrapper.description',
            }}
            renderIcon="https://dih6yo2fd8n78.cloudfront.net/icon1.png"
            listings={isArrayLength(listings) ? listings.slice(20, 40) : []}
            showPartners
            isMobileSection={isMobileSection}
          />
        </div>
        <div className={css.howToBecomeHostSection}>
          <SectionHostMobile intl={intl} isMobileSection={isMobileSection} />
        </div>
        <div
          className={classNames(css.fullSliderSection, css.greenBgSection, {
            [css.imageGallarySectionMobile]: isMobileSection,
          })}
        >
          <SeoPageMobile
            blogs={isArrayLength(allBlogs) && allBlogs.slice(10, 20)}
            intl={intl}
            hidePartners
            isMobileSection={isMobileSection}
          />
        </div>
        <div className={css.newsletterSection}>
          <NewsletterSection onSendNewsLetter={() => {}} isMobileSection={isMobileSection} />
        </div>
        <div
          className={classNames(css.imageGallarySection, {
            [css.imageGallarySectionMobile]: isMobileSection,
          })}
        >
          <SeoPageImageMobile
            intl={intl}
            sectionIndex={2}
            isMobileSection={isMobileSection}
            homepageSections={homepageSections}
          />
        </div>
        <div
          className={classNames(css.compositeSection, {
            [css.compositeSectionMobile]: isMobileSection,
          })}
        >
          <SectionCar isMobileSection={isMobileSection} />
        </div>
        <div
          className={classNames(css.imageGallarySection, {
            [css.imageGallarySectionMobile]: isMobileSection,
          })}
        >
          <SeoPageImageMobile
            intl={intl}
            sectionIndex={3}
            isMobileSection={isMobileSection}
            homepageSections={homepageSections}
          />
        </div>
      
        <div
          className={classNames(css.imageGallarySection, {
            [css.imageGallarySectionMobile]: isMobileSection,
          })}
        >
          <SeoPageImageMobile
            intl={intl}
            sectionIndex={4}
            isMobileSection={isMobileSection}
            homepageSections={homepageSections}
          />
        </div>
        <div className={css.campingInSection}>
          <CampingInSection isMobileSection={isMobileSection} />
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    // Used isEqual for a deep comparison
    return isEqual(prevProps, nextProps);
  }
);

export default RenderMobileLandingPage;
