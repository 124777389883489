import React from 'react';
import { isArrayLength } from '../../../util/genericHelpers';
import { Button } from '../../../components';
import classNames from 'classnames';

import css from './Sections.module.css';

export const renderIcons = {
  1: <img src="https://dih6yo2fd8n78.cloudfront.net/icon3.png" />,
  2: <img src="https://dih6yo2fd8n78.cloudfront.net/icon2.png" />,
  3: <img src="https://dih6yo2fd8n78.cloudfront.net/icon6.png" />,
  4: <img src="https://dih6yo2fd8n78.cloudfront.net/icon7.png" />,
};

function SeoImageSectionLanding(props) {
  const { isInverted, sectionIndex, homepageSections } = props;
  const section = isArrayLength(homepageSections) && homepageSections[sectionIndex - 1];
  if (!section) {
    <></>;
  }

  const seoLinks = section?.seo;

  return (
    <div className={classNames(css.imageGallary, isInverted ? css.imageGallary2 : null)}>
      <div className={css.gallarySec}>
        {isArrayLength(seoLinks) &&
          seoLinks.map((s, i) => (
            <div
              key={i}
              className={css.imgBlock}
              onClick={() => {
                if (typeof window !== 'undefined') {
                  window.location.href = s?.link;
                }
              }}
            >
              <img src={s?.image?.data?.attributes?.url} alt={s?.title} />
              <h2>{s?.title}</h2>
            </div>
          ))}
      </div>
      <div className={css.sectionFilter}>
        {renderIcons[sectionIndex]}
        <h2>{section?.title}</h2>
        <p>{section?.description} </p>
        <Button
          type="button"
          onClick={() => {
            if (typeof window !== 'undefined') {
              window.location.href = section?.link;
            }
          }}
        >
          {section?.buttonText}
        </Button>
      </div>
    </div>
  );
}

export default SeoImageSectionLanding;
