import React from 'react';
import Slider from 'react-slick';
import { Button } from '../../../components';
import css from './MobileSections.module.css';
import { isArrayLength, keepValidGermanChars } from '../../../util/genericHelpers';
import '../../../styles/slick/slick-theme.css';
import '../../../styles/slick/slick.css';
import { GENERAL_SEO } from '../../../util/enums';
import classNames from 'classnames';
import { getBlogData, partnerImages } from '../../../util/dataExtractors';
import { createResourceLocatorString } from '../../../util/routes';
import routeConfiguration from '../../../routing/routeConfiguration';
import { createSlug } from '../../../util/urlHelpers';

function SeoPageMobile(props) {
  const {
    intl,
    hidePartners,
    blogs,
    history,
    renderRedirectIcons,
    showAmenities,
    searchKey,
    isMobileSection,
    isSeoSlider,
  } = props;

  const sliderSettings = {
    dots: false,
    arrows: isMobileSection ? false : true,
    infinite: true,
    centerMode: true,
    speed: 500,
    slidesToShow: 1.1, // Display 4 items on desktop
    slidesToScroll: 1, // Scroll 1 item at a time
    variableWidth: false,
    focusOnSelect: false,
    responsive: [
      {
        breakpoint: 1024, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 1.1,
          variableWidth: false,
        },
      },
      {
        breakpoint: 768, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 1.1,
          variableWidth: false,
        },
      },
      {
        breakpoint: 580, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 1.1,
          variableWidth: false,
        },
      },
      {
        breakpoint: 480, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 1.01,
          variableWidth: false,
        },
      },
    ],
  };

  if (!isArrayLength(blogs)) {
    return <></>;
  }

  const submitButton = intl.formatMessage({
    id: 'SeoSectionLanding.submitButton',
  });

  return (
    <>
      {hidePartners ? null : (
        <div className={css.ourPartnerSection}>
          <h2> {intl.formatMessage({ id: 'SeenOnWrapper.seenOn' })}</h2>
          <div className={css.seenOn}>
            {partnerImages.map((img, i) => {
              return (
                <div className={css.partnerImg} key={i}>
                  <img src={img} key={i} alt={GENERAL_SEO} className={css.partnerImage} />
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div className={css.seoHeading}>
        <h2> {intl.formatMessage({ id: 'SeenOnWrapper.seoHeading' })}</h2>
      </div>
      <div
        className={classNames(css.fullSlider, {
          [css.mobileFullSlider]: isSeoSlider,
        })}
      >
        <Slider {...sliderSettings}>
          {isArrayLength(blogs) &&
            blogs.map((b, i) => {
              const { title, description = '', image } = getBlogData(b) || {};
              return (
                <div key={i} className={css.sliderItem}>
                  <img src={image} alt={title} />
                  <div className={css.sliderItemContent}>
                    <p>
                      <span>{title}</span>{' '}
                      {description ? `${keepValidGermanChars(description.replace(/#/g, ' '))}` : ''}
                    </p>
                    <Button
                      onClick={() => {
                        history.push(
                          createResourceLocatorString(
                            'SingleBlogPage',
                            routeConfiguration(),
                            {
                              id: b?.id,
                              slug: createSlug(title),
                            },
                            {}
                          )
                        );
                      }}
                      type="button"
                    >
                      {submitButton}
                    </Button>
                  </div>
                </div>
              );
            })}
        </Slider>
      </div>
      {showAmenities && (
        <div className={css.ourPartnerSection}>
          {isArrayLength(renderRedirectIcons) ? (
            <h2> {intl.formatMessage({ id: 'SeenOnWrapper.pitchLocations' })}</h2>
          ) : null}
          <div className={css.seenOn}>
            {isArrayLength(renderRedirectIcons) &&
              renderRedirectIcons.map((rri, i) => (
                <div
                  key={i}
                  className={classNames(css.listingImg, {
                    [css.listingImgMobile]: showAmenities,
                  })}
                  onClick={() => {
                    history.push(
                      createResourceLocatorString(
                        'SearchPage',
                        routeConfiguration(),
                        {},
                        { [searchKey]: formatStrToQueryStr([rri?.key]) }
                      )
                    );
                  }}
                >
                  <img className={css.partnerImage} src={rri?.icon} />
                  {/* <h2>{rri?.label}</h2> */}
                </div>
              ))}
          </div>
        </div>
      )}
    </>
  );
}

export default SeoPageMobile;
