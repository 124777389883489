import React from 'react';
import { useIntl } from '../../../util/reactIntl';
import LandingSearchForm from './LandingSearchForm/LandingSearchForm';
import { NamedLink } from '../../../components';
import { searchTitle } from '../../../routing/routeConfiguration';
import yaavLogo from '../../../assets/youandaview.png';

import css from './MobileSectionHero.module.css';
import '../../../styles/slick/slick-theme.css';
import '../../../styles/slick/slick.css';
import { GENERAL_SEO } from '../../../util/enums';

function MobileSectionHero(props) {
  const { history, viewport } = props;

  const intl = useIntl();
  const handleSubmit = () => {
    const urlString = `Europe&bounds=62.33995248%2C19.17157943%2C25.98756225%2C-11.07927511&pub_isInfoOnly=false`;
    history.push(`/${searchTitle}?${urlString}`);
  };

  return (
    <div className={css.heroContainer}>
      <div className={css.heroContentWrapper}>
        <div className={css.contentWidth}>
          <div className={css.heroContent}>
            <img src={yaavLogo} alt={GENERAL_SEO} />
            {/* <div className={css.heroSearchSec}>
              <LandingSearchForm
                rootClassName={css.landingSearchForm}
                onSubmit={handleSubmit}
                intl={intl}
                viewport={viewport}
              />
            </div> */}
          </div>
        </div>
        <div className={css.contentWidth} style={{ textAlign: 'center' }}>
          <NamedLink
            className={css.searchAllBtn}
            name="SearchPage"
            to={{
              search:
                '?Europe&bounds=62.33995248%2C19.17157943%2C25.98756225%2C-11.07927511&pub_isInfoOnly=false',
            }}
          >
            {intl.formatMessage({ id: 'SectionHero.searchAll' })}
          </NamedLink>
        </div>
      </div>
    </div>
  );
}

export default MobileSectionHero;
