import React, { useRef } from 'react';
import Slider from 'react-slick';
import { Button, IconNextArrow, IconPrevArrow, Icons } from '../../../components';
import { useIntl } from '../../../util/reactIntl';

import css from './MobileSections.module.css';
import '../../../styles/slick/slick-theme.css';
import '../../../styles/slick/slick.css';

import { EXTERNAL_LINKS } from '../../../util/enums';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { createResourceLocatorString } from '../../../util/routes';
import routeConfiguration from '../../../routing/routeConfiguration';

const conceptURLs = [
  'https://dih6yo2fd8n78.cloudfront.net/4.1+Host-min.jpg',
  'https://dih6yo2fd8n78.cloudfront.net/4.2+Host-min.jpg',
  'https://dih6yo2fd8n78.cloudfront.net/4.3+Host-min.jpg',
];

function SectionHostMobile(props) {
  const { isMobileSection } = props;
  function RenderNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <IconNextArrow className={css.icon} />
      </div>
    );
  }

  function RenderPrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <IconPrevArrow className={css.icon} />
      </div>
    );
  }
  const ref = useRef(null);

  const sliderSettings = {
    dots: false,
    arrows: isMobileSection ? true : false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 1, // Display 4 items on desktop
    slidesToScroll: 1, // Scroll 1 item at a time
    variableWidth: false,
    focusOnSelect: false,
    centerMode: false,
    nextArrow: <RenderNextArrow />,
    prevArrow: <RenderPrevArrow />,
    responsive: [
      {
        breakpoint: 1440, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 1,
          variableWidth: false,
        },
      },
      {
        breakpoint: 1280, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 1,
          variableWidth: false,
        },
      },
      {
        breakpoint: 768, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 1,
          variableWidth: false,
        },
      },
      {
        breakpoint: 580, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 1,
          variableWidth: false,
        },
      },
      {
        breakpoint: 480, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 1,
          variableWidth: false,
        },
      },
    ],
  };

  const intl = useIntl();
  const history = useHistory();

  const redirectToCalendly = () => {
    if (typeof window !== 'undefined') {
      window.open(EXTERNAL_LINKS.calendly, '_blank');
    }
  };

  const redirectToCreateListing = () => {
    history.push(createResourceLocatorString('NewListingPage', routeConfiguration(), {}, {}));
  };

  return (
    <div className={css.becomeHostSection}>
      <Slider {...sliderSettings}>
        <div className={css.parkingSliderItem}>
          <img src={conceptURLs[0]} alt={intl.formatMessage({ id: 'BecomeHostSection.signUp' })} />
          <div className={css.itemContent}>
            {/* <Icons name="userIcon" /> */}
            <h2>{intl.formatMessage({ id: 'BecomeHostSection.signUp' })}</h2>
            <p>{intl.formatMessage({ id: 'BecomeHostSection.signUpDescription' })}</p>
            <div className={css.bottomButtonsWrapper}>
              <Button type="button" className={css.whiteBorderBtn} onClick={redirectToCalendly}>
                {intl.formatMessage({ id: 'BecomeHostSection.scheduleCall' })}
              </Button>
              <Button type="button" onClick={redirectToCreateListing}>
                {intl.formatMessage({ id: 'BecomeHostSection.createListing' })}
              </Button>
            </div>
          </div>
        </div>
        <div className={css.parkingSliderItem} ref={ref}>
          <img
            src={conceptURLs[1]}
            alt={intl.formatMessage({ id: 'BecomeHostSection.hostGuests' })}
          />
          <div className={css.itemContent}>
            {/* <Icons name="calendarIcon" /> */}
            <h2>{intl.formatMessage({ id: 'BecomeHostSection.hostGuests' })}</h2>
            <p>{intl.formatMessage({ id: 'BecomeHostSection.hostGuestsDescription' })}</p>
            <div className={css.bottomButtonsWrapper}>
              <Button className={css.whiteBorderBtn} onClick={redirectToCalendly}>
                {intl.formatMessage({ id: 'BecomeHostSection.scheduleCall' })}
              </Button>
              <Button type="button" onClick={redirectToCreateListing}>
                {intl.formatMessage({ id: 'BecomeHostSection.createListing' })}
              </Button>
            </div>
          </div>
        </div>
        <div className={css.parkingSliderItem} ref={ref}>
          <img
            src={conceptURLs[2]}
            alt={intl.formatMessage({ id: 'BecomeHostSection.earnMoney' })}
          />
          <div className={css.itemContent}>
            {/* <Icons name="moneyHandIcon" /> */}
            <h2>{intl.formatMessage({ id: 'BecomeHostSection.earnMoney' })}</h2>
            <p>{intl.formatMessage({ id: 'BecomeHostSection.earnMoneyDescription' })}</p>
            <div className={css.bottomButtonsWrapper}>
              <Button className={css.whiteBorderBtn} onClick={redirectToCalendly}>
                {intl.formatMessage({ id: 'BecomeHostSection.scheduleCall' })}
              </Button>
              <Button type="button" onClick={redirectToCreateListing}>
                {intl.formatMessage({ id: 'BecomeHostSection.createListing' })}
              </Button>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
}

export default SectionHostMobile;
