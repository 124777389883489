import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import { IconNextArrow, IconPrevArrow, NamedLink } from '../../../components';
import { useIntl } from '../../../util/reactIntl';
import { locationImages } from '../Sections/HowItWorks';

import css from './MobileSections.module.css';
import '../../../styles/slick/slick-theme.css';
import '../../../styles/slick/slick.css';

const conceptURLs = [
  'https://dih6yo2fd8n78.cloudfront.net/2.1+Concept.jpg',
  'https://dih6yo2fd8n78.cloudfront.net/2.2+Concept.jpg',
  'https://dih6yo2fd8n78.cloudfront.net/2.3+Concept-min.jpg',
];

function SingleCarSlider(props) {
  const { isMobileSection } = props;
  function RenderNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <IconNextArrow className={css.icon} />
      </div>
    );
  }

  function RenderPrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <IconPrevArrow className={css.icon} />
      </div>
    );
  }

  const ref = useRef(null);

  const sliderSettings = {
    dots: false,
    arrows: isMobileSection ? true : false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 1, // Display 4 items on desktop
    slidesToScroll: 1, // Scroll 1 item at a time
    variableWidth: false,
    focusOnSelect: false,
    centerMode: false,
    nextArrow: <RenderNextArrow />,
    prevArrow: <RenderPrevArrow />,
    responsive: [
      {
        breakpoint: 1440, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 1,
          variableWidth: false,
        },
      },
      {
        breakpoint: 1280, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 1,
          variableWidth: false,
        },
      },
      {
        breakpoint: 768, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 1,
          variableWidth: false,
        },
      },
      {
        breakpoint: 580, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 1,
          variableWidth: false,
        },
      },
      {
        breakpoint: 480, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 1,
          variableWidth: false,
        },
      },
    ],
  };

  const intl = useIntl();

  const submitButton = intl.formatMessage({ id: 'HowItWorks.submitButton' });
  const descriptionImage = (title, subtitle, image, index) => {
    const text = <span className={css.locationName}>{title}</span>;
    const sub = <span>{subtitle}</span>;

    return (
      <div className={css.parkingSliderItem} key={index} ref={ref}>
        <img src={conceptURLs[index]} alt={title} />
        <div className={css.itemContent}>
          <div className={css.heading}>
            {/* <img src={image} alt={title} className={css.locationImage} /> */}
            <span>{text}</span>
          </div>
          <p>{sub}</p>
          <div className={css.searchBtnSec}>
            <NamedLink className={css.whiteBorderBtn} name="SearchPage">
              {submitButton}
            </NamedLink>
          </div>
        </div>
      </div>
    );
  };
  const locationElements = locationImages.map((location, index) =>
    descriptionImage(
      intl.formatMessage({ id: location.labelId }),
      intl.formatMessage({ id: location.sublabelId }),
      location.imageUrl,
      index
    )
  );
  return (
    <div className={css.parkingSpaces}>
      <Slider {...sliderSettings}>{locationElements}</Slider>
    </div>
  );
}

export default SingleCarSlider;
