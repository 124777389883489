import React from 'react';
import { useIntl } from '../../../util/reactIntl';
import { formatStrToQueryStr, getOptionImage, isArrayLength } from '../../../util/genericHelpers';
import { Button, IconNextArrow, IconPrevArrow, LandingListingCard } from '../../../components';
import Slider from 'react-slick';
import { GENERAL_SEO } from '../../../util/enums';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { createResourceLocatorString } from '../../../util/routes';
import routeConfiguration from '../../../routing/routeConfiguration';
import { partnerLogos } from '../../../util/dataExtractors';
import classNames from 'classnames';

import css from './MobileSections.module.css';
import '../../../styles/slick/slick-theme.css';
import '../../../styles/slick/slick.css';

const FilterBySection = props => {
  const {
    listings,
    renderRedirectIcons,
    translations,
    renderIcon,
    showAmenities,
    showPartners,
    isMobileSection,
  } = props;

  const intl = useIntl();
  const history = useHistory();

  function RenderNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <IconNextArrow className={css.icon} />
      </div>
    );
  }

  function RenderPrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <IconPrevArrow className={css.icon} />
      </div>
    );
  }

  const sliderSettings = {
    dots: false,
    arrows: isMobileSection ? false : true,
    nextArrow: <RenderNextArrow />,
    prevArrow: <RenderPrevArrow />,
    infinite: false,
    speed: 500,
    slidesToShow: 2.5,
    slidesToScroll: 1,
    variableWidth: false,
    focusOnSelect: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 2.3,
          variableWidth: false,
        },
      },
      {
        breakpoint: 1300, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 2.2,
          variableWidth: false,
        },
      },
      {
        breakpoint: 992, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 1.7,
          variableWidth: false,
        },
      },
      {
        breakpoint: 580, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 1.5,
          variableWidth: false,
        },
      },
      {
        breakpoint: 480, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 1.2,
          variableWidth: false,
        },
      },
    ],
  };

  return (
    <div className={css.moreAbout}>
      <div className={css.sectionListings}>
        <div className={css.sectionFilter}>
          <img src={renderIcon} />
          <h2>{translations?.title ? intl.formatMessage({ id: translations?.title }) : null}</h2>
          <p>
            {translations?.description
              ? intl.formatMessage({ id: translations?.description })
              : null}
          </p>
        </div>
        <div className={css.halfSlider}>
          <Slider {...sliderSettings}>
            {isArrayLength(listings)
              ? listings.map(l => (
                  <LandingListingCard
                    className={css.listingCard}
                    key={l?.id?.uuid}
                    listing={l}
                    setActiveListing={() => {}}
                    isMobileSection={isMobileSection}
                  />
                ))
              : null}
          </Slider>
        </div>
        {translations?.description ? (
          <div className={css.exploreNowButton}>
            <Button
              onClick={() => {
                history.push(
                  createResourceLocatorString('SearchPage', routeConfiguration(), {}, {})
                );
              }}
              type="button"
            >
              {intl.formatMessage({ id: 'FilterBySection.exploreNow' })}
            </Button>
          </div>
        ) : null}
      </div>
      {showAmenities && (
        <div className={css.ourPartnerSection}>
          {isArrayLength(renderRedirectIcons) ? (
            <h2> {intl.formatMessage({ id: 'SeenOnWrapper.popularSearches' })}</h2>
          ) : null}
          <div className={css.seenOn}>
            {isArrayLength(renderRedirectIcons) &&
              renderRedirectIcons.map((rri, i) => (
                <div
                  key={i}
                  className={classNames(css.listingImg, {
                    [css.listingImgMobile]: showAmenities,
                  })}
                  onClick={() => {
                    history.push(
                      createResourceLocatorString(
                        'SearchPage',
                        routeConfiguration(),
                        {},
                        { [searchKey]: formatStrToQueryStr([rri?.key]) }
                      )
                    );
                  }}
                >
                  <img className={css.partnerImage} src={rri?.icon} />
                  {/* <h2>{rri?.label}</h2> */}
                </div>
              ))}
          </div>
        </div>
      )}
      {showPartners && (
        <div className={css.ourPartnerSection}>
          <h2> {intl.formatMessage({ id: 'SeenOnWrapper.ourPartners' })}</h2>
          <div className={css.seenOn}>
            {partnerLogos.map((img, i) => {
              return (
                <div className={css.partnerImg} key={i}>
                  <img src={img} key={i} alt={GENERAL_SEO} className={css.partnerImage} />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterBySection;
