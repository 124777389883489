import React from 'react';
import { useIntl } from '../../../util/reactIntl';
import css from './Sections.module.css';
import { NamedLink } from '../../../components';

export const locationImages = [
  {
    labelId: 'HowItWorks.title',
    sublabelId: 'HowItWorks.description',
    imageUrl: 'https://dih6yo2fd8n78.cloudfront.net/generic-photos/campingatforest5_s.png',
  },
  {
    labelId: 'HowItWorks.title1',
    sublabelId: 'HowItWorks.description1',
    imageUrl: 'https://dih6yo2fd8n78.cloudfront.net/generic-photos/campingonriver6_s.png',
  },
  {
    labelId: 'HowItWorks.title2',
    sublabelId: 'HowItWorks.description2',
    imageUrl: 'https://dih6yo2fd8n78.cloudfront.net/generic-photos/campingatpond7_s.png',
  },
];

const descriptionImage = (title, subtitle, image, index) => {
  const text = <span className={css.locationName}>{title}</span>;
  const sub = <span>{subtitle}</span>;

  const renderLocationDescription = () => {
    return (
      <>
        <h2 className={css.linkText}>{text}</h2>
        <p className={css.locationSubtitle}>{sub}</p>
      </>
    );
  };

  return (
    <div className={css.location} key={index}>
      <div className={css.imageWrapper}>
        <img src={image} alt={title} className={css.locationImage} />
      </div>
      {index > 1 ? (
        <div className={css.renderLocationDescription}>{renderLocationDescription()}</div>
      ) : (
        renderLocationDescription()
      )}
    </div>
  );
};

function HowItWorks() {
  const intl = useIntl();
  const locationElements = locationImages.map((location, index) =>
    descriptionImage(
      intl.formatMessage({ id: location.labelId }),
      intl.formatMessage({ id: location.sublabelId }),
      location.imageUrl,
      index
    )
  );

  const howitWorksTitle = intl.formatMessage({ id: 'HowItWorks.heading' }, { lineBreak: <br /> });
  const submitButton = intl.formatMessage({ id: 'HowItWorks.submitButton' });

  return (
    <div className={css.howItWorksContent}>
      <h2>{howitWorksTitle}</h2>
      <div className={css.locationElements}>{locationElements}</div>
      <NamedLink className={css.searchBtn} name="SearchPage">
        {submitButton}
      </NamedLink>
    </div>
  );
}

export default HowItWorks;
