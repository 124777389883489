import React from 'react';
import { useIntl } from '../../../util/reactIntl';
import { Button } from '../../../components';
import classNames from 'classnames';

import css from './CommonSections.module.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { createResourceLocatorString } from '../../../util/routes';
import routeConfiguration, { searchTitle } from '../../../routing/routeConfiguration';

function SectionCar(props) {
  const { isMobileSection, isCompositeSection } = props;
  const intl = useIntl();
  const history = useHistory();
  return (
    <div
      className={classNames(css.campsiteContent, {
        [css.campsiteContentMobile]: isMobileSection,
      })}
    >
      <h2>
        {intl.formatMessage({
          id: isCompositeSection ? 'SectionCampsites.title' : 'SectionCar.title',
        })}
      </h2>
      <p>
        {intl.formatMessage({
          id: isCompositeSection ? 'SectionCampsites.description' : 'SectionCar.description',
        })}
      </p>
      <Button
        type="button"
        onClick={() => {
          const urlString = isCompositeSection
            ? `Europe&bounds=62.33995248%2C19.17157943%2C25.98756225%2C-11.07927511&pub_isInfoOnly=false`
            : '';
          history.push(`/${isCompositeSection ? searchTitle : 'blogs'}?${urlString}`);
        }}
        className={css.searchBtn}
      >
        {intl.formatMessage({
          id: isCompositeSection ? 'SectionCampsites.submitButton' : 'SectionCar.submitButton',
        })}
      </Button>
    </div>
  );
}

export default SectionCar;
